import { PageName, UiComponentName } from '@ms/yammer-telemetry-support';
import { createContext } from 'react';

export interface PageTelemetryContextValue {
  readonly page: PageName;
  readonly path?: string;
  readonly hero: UiComponentName;
  readonly isRoutelessApp: boolean;
  readonly reportHeroComponentRendered: (component: UiComponentName) => void;
}

export const PageTelemetryContext = createContext<PageTelemetryContextValue | undefined>(undefined);
