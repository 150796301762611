import { GlobalCopilotNavButton } from '@ms/yammer-copilot/dist/integration';
import { AppNavigation } from '@ms/yammer-features-app-navigation';
import { CreateCampaignClickedEntrypointEnum } from '@ms/yammer-hooks/dist/campaignCreation';
import { useAndReportExperimentTreatment } from '@ms/yammer-hooks/dist/experiments';
import { UserProfileMenuButton } from '@ms/yammer-left-rail';
import { UniversalCreateButtonProvider } from '@ms/yammer-universal-create-button';
import { LoadableUserSettingsNav, userSettingsPageRoute } from '@ms/yammer-user-settings-page';
import { useCampaignCreateModal } from '@ms/yammer-web-components/dist/campaignCreateEdit/CampaignCreateModal/hooks/useCampaignCreateModal';
import { useGroupCreateModal } from '@ms/yammer-web-components/dist/groupCreateEdit/LoadableGroupCreateModal';
import NavigationCommunities from '@ms/yammer-web-components/dist/navigation/NavigationCommunities';
import { usePostCreateModal } from '@ms/yammer-web-components/dist/publisher/LoadableUniversalCreatePublisherModal';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useHomeLinkActionButtons } from './hooks';

const DefaultNav: FC = () => {
  const homeLinkActionButtons = useHomeLinkActionButtons();
  const isGlobalCopilotEnabled = useAndReportExperimentTreatment('VivaEngageGlobalCopilot');
  const campaignCreateModal = useCampaignCreateModal(CreateCampaignClickedEntrypointEnum.UniversalCreateButton);

  const profileLinkButtons = isGlobalCopilotEnabled ? [<GlobalCopilotNavButton key="globalCopilotNavButton" />] : [];

  return (
    <UniversalCreateButtonProvider
      useGroupCreateModal={useGroupCreateModal}
      campaignCreateModal={campaignCreateModal}
      usePostCreateModal={usePostCreateModal}
    >
      <AppNavigation
        communitiesNavLinksContent={<NavigationCommunities />}
        userProfileLinkActionButtons={[...profileLinkButtons, <UserProfileMenuButton key="userProfileMenuButton" />]}
        homeLinkActionButtons={homeLinkActionButtons}
      />
    </UniversalCreateButtonProvider>
  );
};

export const AppLeftNav: React.FC = () => (
  <Switch>
    <Route path={userSettingsPageRoute}>
      <LoadableUserSettingsNav />
    </Route>
    <Route>
      <DefaultNav />
    </Route>
  </Switch>
);
