import { createContext } from 'react';

import { UIFeatures } from '../types';

/**
 * Union of allowed types in `UIFeatures`.
 * Please **DO NOT** add any other type without starting a discussion in {@link https://web.yammer.com.yammerseat.yammer.seatsa.myshn.eu/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMzMxMDc3NiJ9/new Mordern Clients}. Also make sure to tag the authors below 👇
 * @author Brian Stone <brianstone@microsoft.com>
 * @author Rafael Tavares <rafaelro@microsoft.com>
 * @author Richard Tagger <ritagger@microsoft.com>
 */
type UIFeaturesAllowedTypes = boolean;

type ValidateUIFeaturesNestedObject<T> = T extends {
  [K in keyof T]?: T[K] extends infer U ? (U extends UIFeaturesAllowedTypes ? U : never) : never;
}
  ? T
  : never;

type ValidateUIFeatures<T> = T extends { [K in keyof T]?: ValidateUIFeaturesNestedObject<T[K]> } ? T : never;

const UIFeatureContext = createContext<ValidateUIFeatures<UIFeatures> | undefined>(undefined);
export default UIFeatureContext;
