import { AuthenticatorContextProvider } from '@ms/yammer-authenticator-context';
import { getAuthenticator } from '@ms/yammer-data/dist/authentication';
import { Store } from '@ms/yammer-data/dist/state/types';
import { ExperimentContextProvider } from '@ms/yammer-experiments-context';
import {
  useAndReportExperimentTreatment,
  useExperimentTreatmentWithoutReporting as useExperimentTreatment,
  useReportExperimentTreatmentCallback,
} from '@ms/yammer-hooks/dist/experiments';
import { useIsFeatureRolloutEnabled } from '@ms/yammer-hooks/dist/featureRollouts';
import { PageSectionRefsProvider } from '@ms/yammer-web-components/dist/app/PageSectionRefs';
import { ReduxTelemetryContextProvider } from '@ms/yammer-web-components/dist/telemetry';
import { ReduxTranslationsContextProvider } from '@ms/yammer-web-components/dist/translations';
import { AnnouncerProvider } from '@ms/yammer-web-core-ui/dist/components/Announcer';
import GriffelNoopRendererProvider from '@ms/yammer-web-core-ui/dist/components/GriffelNoopRendererProvider';
import React from 'react';
import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from '../../../components/App';
import { YammerWebState } from '../../../state/types';

import RelayAppWrapper from './RelayAppWrapper';
import { getBasename } from './getBasename';
import { getErrorComponent } from './getErrorComponent';

const experimentContext = {
  useReportExperimentTreatmentCallback,
  useIsFeatureRolloutEnabled,
  useExperimentTreatment,
  useAndReportExperimentTreatment,
};

export interface RendererOptions {
  readonly store: Store<YammerWebState>;
  readonly target: HTMLElement;
  readonly persistor?: Persistor;
  readonly isFailure?: boolean;
  readonly etag?: string;
}

export type Renderer = (options: RendererOptions) => void;

const renderer: Renderer = ({ store, target, persistor, isFailure = false, etag }) => {
  const children = isFailure ? getErrorComponent(store) : <App />;

  const basename = getBasename(store);

  const content = (
    <HelmetProvider>
      <BrowserRouter basename={basename}>
        <CompatRouter>
          <PageSectionRefsProvider>
            <AnnouncerProvider>{children}</AnnouncerProvider>
          </PageSectionRefsProvider>
        </CompatRouter>
      </BrowserRouter>
    </HelmetProvider>
  );

  const contentWithOptionalPersistGate = persistor ? (
    <PersistGate loading={null} persistor={persistor}>
      {content}
    </PersistGate>
  ) : (
    content
  );

  render(
    <GriffelNoopRendererProvider>
      <Provider store={store}>
        <ReduxTranslationsContextProvider>
          <ReduxTelemetryContextProvider>
            <AuthenticatorContextProvider authenticator={getAuthenticator()}>
              <ExperimentContextProvider {...experimentContext}>
                <RelayAppWrapper reduxStore={store} etag={etag}>
                  {contentWithOptionalPersistGate}
                </RelayAppWrapper>
              </ExperimentContextProvider>
            </AuthenticatorContextProvider>
          </ReduxTelemetryContextProvider>
        </ReduxTranslationsContextProvider>
      </Provider>
    </GriffelNoopRendererProvider>,
    target
  );
};

export default renderer;
