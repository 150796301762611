import { Depths } from '@fluentui/react/lib/Theme';
import { CSSProperties } from 'react';

export const boxShadowAlphaAsPercent = 7;

/**
 * Determines the amount of box-shadow applied to an element, the "depth" it sits above the parent surface. More info:
 * https://developer.microsoft.com.yammerseat.yammer.seatsa.myshn.eu/en-us/fluentui#/styles/web/elevation
 */
export type Depth =
  /**
   * No depth, no shadow.
   */
  | 'depth0'

  /**
   * One pixel depth.
   */
  | 'depth1'

  /**
   * Slight depth, slight separation from the surface below it. Used when small pieces of UI need depth.
   * Maps to `0 0 2px rgba(0,0,0,0.12),  0 1px 2px rgba(0,0,0,0.14)`
   */
  | 'depth2'

  /**
   * Some depth, some separation from the surface below it. Cards, grid item thumbnails.
   * Maps to `0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108)`
   */
  | 'depth4'

  /**
   * Elevation between surfaces. Sidebar and main content.
   * Maps to `0 1.2px 3.6px rgba(0,0,0,0.03), 0 6.4px 14.4px rgba(0,0,0,0.07)`
   */
  | 'depth6'

  /**
   * Command bars, context menus.
   * Maps to `0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)`
   */
  | 'depth8'

  /**
   * Hover cards, tool tips.
   * Maps to `0 6.4px 14.4px 0 rgba(0,0,0,.132), 0 1.2px 3.6px 0 rgba(0,0,0,.108)`
   */
  | 'depth16'

  /**
   * Panels, popup dialogs.
   * Maps to `0 25.6px 57.6px 0 rgba(0,0,0,.22), 0 4.8px 14.4px 0 rgba(0,0,0,.18)`
   */
  | 'depth64';

const YammerDepths = {
  depth1: '0 0 0 1px rgba(0, 0, 0, 0.132)',
  depth2: '0 0.15px 0.45px rgba(0, 0, 0, 0.11),  0 0.8px 1.8px rgba(0, 0, 0, 0.13)',
  depth6: '0 1.2px 3.6px rgba(0, 0, 0, 0.03), 0 6.4px 14.4px rgba(0, 0, 0, 0.07)',
  ...Depths,
};

export type GetDepthBoxShadow = (depth: Depth) => CSSProperties['boxShadow'];
export const getDepthBoxShadow: GetDepthBoxShadow = (depth) => YammerDepths[depth];
