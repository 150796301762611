import ErrorBoundary, { ErrorBoundaryName } from '@ms/yammer-libs-error-boundary';
import React, { FC } from 'react';

import AppPageRoutesWithTelemetry from './AppPageRoutesWithTelemetry';
import { AppPageRoutesWithTelemetryProps } from './AppPageRoutesWithTelemetry.types';

const AppPageRoutesWithTelemetryBoundary: FC<AppPageRoutesWithTelemetryProps> = (props) => (
  <ErrorBoundary name={ErrorBoundaryName.AppPageRoutesWithTelemetry}>
    <AppPageRoutesWithTelemetry {...props} />
  </ErrorBoundary>
);

export default AppPageRoutesWithTelemetryBoundary;
