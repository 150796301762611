import {
  getDisplayableDocumentHeaderCount,
  getDisplayableUnreadInboxCount,
  getDisplayableUnseenGroupCount,
  getGroupIdsWithUnseenGroupFeedThreads,
  getUnreadInboxCount,
  getUnreadNetworkQuestionInboxCount,
  getUnseenGroupCount,
  hasUnreadInboxThreads,
  hasUnreadNetworkQuestionInboxThreads,
  hasUnseenGroupFeedThreads,
  hasUnseenMyLeadersThreads,
  isInboxWithMoreThreadsOnServer,
} from '@ms/yammer-data/dist/domains/feedCounts/selectors';
import { Group } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseDisplayableUnseenGroupCount = (groupId: Group['id']) => ReturnType<typeof getDisplayableUnseenGroupCount>;
export const useDisplayableUnseenGroupCount: UseDisplayableUnseenGroupCount = (groupId) =>
  useSelector((state) => getDisplayableUnseenGroupCount(state, groupId));

type UseUnseenGroupCount = (groupId: Group['id']) => ReturnType<typeof getUnseenGroupCount>;
export const useUnseenGroupCount: UseUnseenGroupCount = (groupId) =>
  useSelector((state) => getUnseenGroupCount(state, groupId));

type UseDisplayableUnreadInboxCount = () => ReturnType<typeof getDisplayableUnreadInboxCount>;
export const useDisplayableUnreadInboxCount: UseDisplayableUnreadInboxCount = () =>
  useSelector((state) => getDisplayableUnreadInboxCount(state));

type UseHasUnseenMyLeadersThreads = () => ReturnType<typeof hasUnseenMyLeadersThreads>;
export const useHasUnseenMyLeadersThreads: UseHasUnseenMyLeadersThreads = () => useSelector(hasUnseenMyLeadersThreads);

type UseHasUnreadInboxThreads = () => ReturnType<typeof hasUnreadInboxThreads>;
export const useHasUnreadInboxThreads: UseHasUnreadInboxThreads = () => useSelector(hasUnreadInboxThreads);

type UseHasUnreadNetworkQuestionInboxThreads = () => ReturnType<typeof hasUnreadInboxThreads>;
export const useHasUnreadNetworkQuestionInboxThreads: UseHasUnreadNetworkQuestionInboxThreads = () =>
  useSelector(hasUnreadNetworkQuestionInboxThreads);

type UseUnreadNetworkQuestionInboxCount = () => ReturnType<typeof getUnreadNetworkQuestionInboxCount>;
export const useUnreadNetworkQuestionInboxCount: UseUnreadNetworkQuestionInboxCount = () =>
  useSelector(getUnreadNetworkQuestionInboxCount);

type UseUnreadInboxCount = () => ReturnType<typeof getUnreadInboxCount>;
export const useUnreadInboxCount: UseUnreadInboxCount = () => useSelector(getUnreadInboxCount);

type UseDisplayableDocumentHeaderCount = (
  options?: Parameters<typeof getDisplayableDocumentHeaderCount>[1]
) => ReturnType<typeof getDisplayableDocumentHeaderCount>;
export const useDisplayableDocumentHeaderCount: UseDisplayableDocumentHeaderCount = (options) =>
  useSelector((state) => getDisplayableDocumentHeaderCount(state, options));

type UseHasUnseenThreads = (groupId: Group['id']) => ReturnType<typeof hasUnseenGroupFeedThreads>;
export const useHasUnseenThreads: UseHasUnseenThreads = (groupId) =>
  useSelector((state) => hasUnseenGroupFeedThreads(state, groupId));

type UseGroupsWithUnseenThreads = (groupIds: Group['id'][]) => ReturnType<typeof getGroupIdsWithUnseenGroupFeedThreads>;
export const useGroupsWithUnseenThreads: UseGroupsWithUnseenThreads = (groupIds) =>
  useSelector((state) => getGroupIdsWithUnseenGroupFeedThreads(state, groupIds));

type UseIsInboxWithMoreThreadsOnServer = () => ReturnType<typeof isInboxWithMoreThreadsOnServer>;
export const useIsInboxWithMoreThreadsOnServer: UseIsInboxWithMoreThreadsOnServer = () =>
  useSelector((state) => isInboxWithMoreThreadsOnServer(state));
