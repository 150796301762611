import { ComponentWithLegacyClassNameProps } from '../../util/ComponentWithLegacyClassName/props';

export enum SpinnerColor {
  /**
   * Light theme.
   */
  Light = 'light',
  /**
   * Light theme spinner color with gray text.
   */
  Metadata = 'metadata',
  /**
   * Light theme blue spinner with blue text.
   */
  LightBlue = 'light_blue',
  /**
   * Dark theme.
   */
  Dark = 'dark',
}

export enum SpinnerSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  XSmall = 'xSmall',
}

export enum TextPosition {
  Below = 'below',
  Beside = 'beside',
}

export interface SpinnerProps extends ComponentWithLegacyClassNameProps {
  /**
   * Text displayed besides/below the spinner.
   */
  readonly text: string;

  /**
   * Whether to hide text, and only render it for screenreaders.
   * default: false
   */
  readonly hideText?: boolean;

  /**
   * position of the text relative to the spinner.
   * default: TextPosition.Beside
   */
  readonly textPosition?: TextPosition;

  /**
   * Color of the spinner.
   * default: SpinnerColor.Light
   */
  readonly color?: SpinnerColor;

  /**
   * Size of the spinner.
   * default: SpinnerSize.Medium
   */
  readonly size?: SpinnerSize;

  /**
   * Will center the Spinner horizontally within its container.
   */
  readonly isCentered?: boolean;
}
