import { Options as OverlayScrollbarsOptions } from 'overlayscrollbars';
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';
import React, { CSSProperties, PropsWithChildren, forwardRef, useMemo, useRef } from 'react';

import 'overlayscrollbars/overlayscrollbars.css';

import { useTheme } from '../../util/theme';

import { getInlineStyles, useClassNames } from './Scrollable.styles';
import { ScrollableProps } from './Scrollable.types';
import { ScrollableContext } from './context';
import { getContainerElementFromOverlayScrollbarsRef } from './getContainerElementFromOverlayScrollbarsRef';

const Scrollable = forwardRef<HTMLElement | null, PropsWithChildren<ScrollableProps>>(
  ({ children, height, maxHeight, width, horizontal = false, scrollbarOffset = 0, disableScrolling = false }, ref) => {
    const scrollRef = useRef<OverlayScrollbarsComponentRef>(null);

    // eslint-disable-next-line @ms/no-react-namespace
    React.useImperativeHandle<HTMLElement | null, HTMLElement | null>(ref, () =>
      getContainerElementFromOverlayScrollbarsRef(scrollRef)
    );

    const defaultHeight = disableScrolling ? 'auto' : '100%';
    const resolvedHeight = height ?? defaultHeight;

    const theme = useTheme();
    const classNames = useClassNames(horizontal);
    const inlineStyles = getInlineStyles({
      height: resolvedHeight,
      maxHeight,
      width,
      scrollbarOffset,
      backgroundColor: theme.palette.neutralSecondaryAlt,
    }) as CSSProperties;

    const options = useMemo((): OverlayScrollbarsOptions => {
      const overflow = disableScrolling
        ? { x: 'hidden', y: 'hidden' }
        : { x: horizontal ? 'scroll' : 'hidden', y: horizontal ? 'hidden' : 'scroll' };

      return {
        overflow,
        scrollbars: { theme: 'chromatic-ignore', autoHide: horizontal ? 'never' : 'move', autoHideDelay: 500 },
      } as unknown as OverlayScrollbarsOptions;
    }, [disableScrolling, horizontal]);

    const scrollContainerElement = disableScrolling ? null : getContainerElementFromOverlayScrollbarsRef(scrollRef);

    return (
      <OverlayScrollbarsComponent
        className={classNames.scrollable}
        options={options}
        ref={scrollRef}
        style={inlineStyles}
        data-testid="overlay-scrollbar"
      >
        <ScrollableContext.Provider value={scrollContainerElement}>{children}</ScrollableContext.Provider>
      </OverlayScrollbarsComponent>
    );
  }
);

/**
 * A scrollable area that renders a custom scrollbar.
 * Note that the component has a default height of 100%, so your feature may need to ensure
 * its parent element has the correct height for ScrollableContentColumn to fill.
 */
export default Scrollable;
