import { initializeIcons as initializeFluentIcons } from '@fluentui/react/lib/Icons';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

// In FluentUI v8, the default base url for icons was updated to a url that violates the security CSP rules
// so we can no longer rely on that. This is the url for the old FluentUI CDN.
const baseUrl = 'https://res-1.cdn.office.net.yammerseat.yammer.seatsa.myshn.eu/files/fabric-cdn-prod_20230815.002/assets';
const iconsPath = `${baseUrl}/icons/`;
const fileIconsPath = `${baseUrl}/item-types/`;

type InitializeIcons = (skipInitializeFluentIcons?: boolean) => void;
export const initializeIcons: InitializeIcons = (skipInitializeFluentIcons = false) => {
  if (!skipInitializeFluentIcons) {
    initializeFluentIcons(iconsPath, { disableWarnings: true });
  }
  initializeFileTypeIcons(fileIconsPath, { disableWarnings: true });
};
