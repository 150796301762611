import { InboxFeedType } from '@ms/yammer-data/dist/normalization/types';

export const inboxRootPageUrl = '/inbox';
export const inboxUnreadPageUrl = '/inbox/unread';
export const inboxDirectMessagesPageUrl = '/inbox/direct';
export const inboxAllPageUrl = '/inbox/all';

type InboxPageUrl = typeof inboxUnreadPageUrl | typeof inboxDirectMessagesPageUrl | typeof inboxAllPageUrl;
const inboxMap: Record<InboxFeedType, InboxPageUrl> = {
  [InboxFeedType.UNREAD]: inboxUnreadPageUrl,
  [InboxFeedType.DIRECT]: inboxDirectMessagesPageUrl,
  [InboxFeedType.ALL]: inboxAllPageUrl,
};

type InboxPage = (feedType: InboxFeedType) => string;
export const inboxPage: InboxPage = (feedType) => inboxMap[feedType];
