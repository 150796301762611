import { setCurrentPageName } from '@ms/yammer-telemetry-support';
import React, { FC, useMemo } from 'react';

import { PageTelemetryContextProviderProps } from './PageTelemetryContextProvider.types';
import { PageTelemetryContext, PageTelemetryContextValue } from './context';
import { usePageLoadTelemetryCallback } from './hooks';

const PageTelemetryContextProvider: FC<PageTelemetryContextProviderProps> = ({
  page,
  path,
  hero,
  isRoutelessApp = false,
  children,
}) => {
  setCurrentPageName(page);
  const reportHeroComponentRendered = usePageLoadTelemetryCallback({
    page,
    path,
    hero,
    isRoutelessApp,
  });

  const value = useMemo<PageTelemetryContextValue>(
    () => ({
      page,
      path,
      hero,
      isRoutelessApp,
      reportHeroComponentRendered,
    }),
    [page, path, hero, isRoutelessApp, reportHeroComponentRendered]
  );

  return <PageTelemetryContext.Provider value={value}>{children}</PageTelemetryContext.Provider>;
};

export default PageTelemetryContextProvider;
