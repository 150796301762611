import { LazyRelayModuleWrapper } from '@ms/yammer-relay/dist/environment';
import React, { FC } from 'react';

import { GlobalCopilotNavButton } from '../components/GlobalCopilot';

export const GlobalCopilotNavButtonIntegration: FC = (props) => (
  <LazyRelayModuleWrapper>
    <GlobalCopilotNavButton {...props} />
  </LazyRelayModuleWrapper>
);
