export const HighContrastEmulationClassName = 'emulate-hc';
export const SuppressHighContrastEmulationBGColorClassName = 'suppress-hc-bgcolor';

export const HighContrastEmulationColorMap = {
  windowtext: 'white', // Text color
  window: 'black', // Background color
  linktext: '#FFFD54', // Link color
  graytext: '#00FF00', // Text color for disabled elements (inc. placeholder text)
  buttontext: 'white', // Button text color
  buttonbackground: 'black', // Button background color
  highlighttext: 'black', // Selected text color
  highlight: '#6CE7FC', // Selected background color.
};

export const HighContrastContextualMenuStyles = {
  list: {
    selectors: {
      [`.${HighContrastEmulationClassName} &`]: {
        border: `1px solid ${HighContrastEmulationColorMap.windowtext}`,
      },
    },
  },
  subComponentStyles: {
    callout: {
      selectors: {
        [`.${HighContrastEmulationClassName} &`]: {
          border: `1px solid ${HighContrastEmulationColorMap.windowtext}`,
        },
      },
    },
    menuItem: {
      root: {
        selectors: {
          [`.${HighContrastEmulationClassName} &`]: {
            selectors: {
              ':focus *, :focus': {
                color: `${HighContrastEmulationColorMap.highlighttext} !important`,
                borderColor: `${HighContrastEmulationColorMap.highlighttext} !important`,
                backgroundColor: `${HighContrastEmulationColorMap.highlight} !important`,
              },
            },
          },
        },
      },
    },
  },
};

export const HighContrastEmulationSelectorStyles = {
  [`:global(body.${HighContrastEmulationClassName} *)`]: {
    backgroundColor: `${HighContrastEmulationColorMap.window} !important`,
    color: `${HighContrastEmulationColorMap.windowtext} !important`,
  },
  [`:global(body.${HighContrastEmulationClassName} .${SuppressHighContrastEmulationBGColorClassName}), :global(body.${HighContrastEmulationClassName} .${SuppressHighContrastEmulationBGColorClassName} *)`]:
    {
      backgroundColor: `unset !important`,
    },
  [`:global(body.${HighContrastEmulationClassName} input), :global(body.${HighContrastEmulationClassName} textarea)`]: {
    color: `${HighContrastEmulationColorMap.windowtext} !important`,
  },
  [`:global(body.${HighContrastEmulationClassName} input::placeholder)`]: {
    color: `${HighContrastEmulationColorMap.graytext} !important`,
  },
  [`:global(body.${HighContrastEmulationClassName} a[href])`]: {
    borderColor: `${HighContrastEmulationColorMap.linktext} !important`,
    color: `${HighContrastEmulationColorMap.linktext} !important`,
  },
  [`:global(body.${HighContrastEmulationClassName} *::selection)`]: {
    color: `${HighContrastEmulationColorMap.highlighttext} !important`,
    backgroundColor: `${HighContrastEmulationColorMap.highlight} !important`,
  },
  [`:global(body.${HighContrastEmulationClassName} button), :global(body.${HighContrastEmulationClassName} input[type=button]), :global(body.${HighContrastEmulationClassName} input[type=submit])`]:
    {
      backgroundColor: `${HighContrastEmulationColorMap.buttonbackground} !important`,
      color: `${HighContrastEmulationColorMap.buttontext} !important`,
      borderColor: `${HighContrastEmulationColorMap.buttontext} !important`,
    },
  [`:global(body.${HighContrastEmulationClassName} button:disabled)`]: {
    color: `${HighContrastEmulationColorMap.graytext} !important`,
    borderColor: `${HighContrastEmulationColorMap.graytext} !important`,
  },
  [`:global(body.${HighContrastEmulationClassName} button *), :global(body.${HighContrastEmulationClassName} a[href] *)`]:
    {
      color: 'inherit !important',
    },
};
