import { ISpinnerStyles } from '@fluentui/react/lib/Spinner';

import { ComponentClassNames } from '../../util/classNames';
import { addAlpha } from '../../util/colors';
import { NumericFontSize, getFluentLineHeightTokenFromNumericFontSize } from '../../util/styles/fluent9';
import { getGutterValue } from '../../util/styles/gutters';
import { ITheme, memoizeFunction, mergeStyleSets } from '../../util/styling';

import { SpinnerColor, SpinnerSize, TextPosition } from './Spinner.types';

const horizontalLabelMargins = {
  [SpinnerSize.XSmall]: `0 0 0 ${getGutterValue(1.5, true)}`,
  [SpinnerSize.Small]: `0 0 0 ${getGutterValue(2, true)}`,
  [SpinnerSize.Medium]: `-1px 0 0 ${getGutterValue(2, true)}`,
  [SpinnerSize.Large]: `0 0 0 ${getGutterValue(2, true)}`,
};

const verticalLabelMargins = {
  ...horizontalLabelMargins,
  [SpinnerSize.Large]: `${getGutterValue(1, true)} 0 0`,
};

const getLabelMargins = (textPosition: TextPosition) => {
  if (textPosition === TextPosition.Below) {
    return verticalLabelMargins;
  }

  return horizontalLabelMargins;
};

type SpinnerClassNames = ComponentClassNames<'spinner' | 'label'>;

export const getClassNames = memoizeFunction(
  (
    size: SpinnerSize,
    isCentered: boolean,
    textSize: NumericFontSize,
    textPosition: TextPosition,
    className?: string
  ): SpinnerClassNames => {
    const lineHeight = getFluentLineHeightTokenFromNumericFontSize(textSize);

    return mergeStyleSets({
      spinner: [
        {
          display: 'flex',
          alignItems: 'center',
          flexDirection: textPosition === TextPosition.Below ? 'column' : 'row',
          minHeight: lineHeight,
          justifyContent: isCentered ? 'center' : undefined,
        },
        className,
      ],
      label: {
        margin: getLabelMargins(textPosition)[size],
      },
    });
  }
);

const getFluentSpinnerColor = (theme: ITheme) => ({
  [SpinnerColor.Light]: theme.palette.neutralDark,
  [SpinnerColor.Metadata]: theme.palette.neutralDark,
  [SpinnerColor.LightBlue]: theme.palette.accent,
  [SpinnerColor.Dark]: theme.palette.white,
});

export const getFluentSpinnerStyles = memoizeFunction((color: SpinnerColor, theme: ITheme): ISpinnerStyles => {
  const fluentSpinnerColor = getFluentSpinnerColor(theme)[color];

  return {
    circle: {
      borderWidth: '2px',
      borderColor: `${addAlpha(fluentSpinnerColor, 100)} ${addAlpha(fluentSpinnerColor, 30)} ${addAlpha(
        fluentSpinnerColor,
        30
      )}`,
    },
  };
});
