import { makeStyles } from '@griffel/react';

import { TextTransform } from '../fonts';

export { TextTransform };

export const useTextTransformClassName = (textTransform?: TextTransform) => {
  const textTransformClassNames = useTextTransformClassNames();

  return textTransform ? textTransformClassNames[textTransform] : undefined;
};

const useTextTransformClassNames = makeStyles<TextTransform>({
  [TextTransform.Capitalize]: {
    textTransform: TextTransform.Capitalize,
  },
  [TextTransform.Lowercase]: {
    textTransform: TextTransform.Lowercase,
  },
  [TextTransform.Uppercase]: {
    textTransform: TextTransform.Uppercase,
  },
});
