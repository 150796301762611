import { GroupFields } from '@ms/yammer-graphql';

import { Group } from '../../../state/types';

import { processGroupAvatar } from './avatar';

export const processGroup: (value: GroupFields) => Group = (value) => ({
  id: value.id,
  officeUnifiedGroupId: value.officeUnifiedGroupId || undefined,
  networkId: value.network.id,
  name: value.displayName,
  description: value.description || undefined,
  isExternal: value.isExternal,
  isMultiTenantOrganizationGroup: value.isMultiTenantOrganizationGroup,
  isOfficial: value.isOfficial,
  guestsCount: value.guestsCount,
  databaseId: value.databaseId,
  hasDefaultAvatar: value.hasDefaultAvatar,
  telemetryId: value.telemetryId,
  groupLinkId: value,
  threadStarterSmallFileUploadUrl: value.threadStarterSmallFileUploadUrl,
  isNetworkQuestionGroup: value.isNetworkQuestionGroup,
  isMoveThreadToThisGroupRestricted: value.isMoveThreadToThisGroupRestricted,
  ...processGroupAvatar(value),
});
