import { useTranslations } from '@ms/yammer-i18n-react';
import SkiplinkStrings from '@ms/yammer-strings/dist/Skiplink';
import FocusMainContentButton from '@ms/yammer-web-components/dist/accessibility/FocusMainContentButton';
import ShowWhenContainsFocus from '@ms/yammer-web-components/dist/accessibility/ShowWhenContainsFocus';
import Block from '@ms/yammer-web-core-ui/dist/components/Block';
import ExternalLink from '@ms/yammer-web-core-ui/dist/components/ExternalLink';
import ThemeProvider from '@ms/yammer-web-core-ui/dist/components/ThemeProvider';
import { defaultTheme } from '@ms/yammer-web-core-ui/dist/util/theme';
import React, { FC } from 'react';

/**
 * Skiplink and accessibility messages.
 *
 * Skiplinks are links to main content that appear at top of the page and allow
 * user to skip over navigation directly to the main part of the page; invisible
 * until they receive focus, so available to mouse and keyboard users, but
 * hidden to mouse-only users.
 */
const Skiplink: FC = () => {
  const t = useTranslations(SkiplinkStrings);
  const screenreaderHelpUrl = 'https://go.microsoft.com.yammerseat.yammer.seatsa.myshn.eu/fwlink/?linkid=2073771';

  return (
    <ThemeProvider theme={defaultTheme}>
      <ShowWhenContainsFocus>
        <Block bottomSpacing={1}>
          <ExternalLink href={screenreaderHelpUrl}>{t.skiplinkTips()}</ExternalLink>
        </Block>
        <FocusMainContentButton text={t.skiplinkSkipTo()} />
      </ShowWhenContainsFocus>
    </ThemeProvider>
  );
};

export default Skiplink;
