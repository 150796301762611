import { useTranslations } from '@ms/yammer-i18n-react';
import NavigationStrings from '@ms/yammer-strings/dist/Navigation';
import Spinner, { SpinnerSize, TextPosition } from '@ms/yammer-web-core-ui/dist/components/Spinner';
import React, { FC } from 'react';

import ErrorMessageBar from '../ErrorRefreshMessageBar';

export interface NavigationSpinnerProps {
  readonly pastDelay?: boolean;
  readonly timedOut?: boolean;
  readonly error?: unknown;
}

/**
 * Spinner shown while a page loads.
 */
const NavigationSpinner: FC<NavigationSpinnerProps> = ({ pastDelay, timedOut, error }) => {
  const t = useTranslations(NavigationStrings);

  if (error) {
    return <ErrorMessageBar />;
  }

  if (timedOut) {
    return (
      <Spinner size={SpinnerSize.Large} text={t.navigationSpinnerTimedOutText()} textPosition={TextPosition.Below} />
    );
  }

  if (pastDelay) {
    return (
      <Spinner size={SpinnerSize.Large} text={t.navigationSpinnerLoadingText()} textPosition={TextPosition.Below} />
    );
  }

  return null;
};

export default NavigationSpinner;
