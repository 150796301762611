import { Spinner as FluentSpinner, SpinnerSize as FluentSpinnerSize } from '@fluentui/react/lib/Spinner';
import React, { FC } from 'react';

import { NumericFontSize } from '../../util/styles/fluent9';
import { useTheme } from '../../util/theme';
import ScreenreaderText from '../ScreenreaderText';
import Text, { TextColor } from '../Text';

import { getClassNames, getFluentSpinnerStyles } from './Spinner.styles.legacy';
import { SpinnerColor, SpinnerProps, SpinnerSize, TextPosition } from './Spinner.types';

const labelColors = {
  [SpinnerColor.Light]: TextColor.Primary,
  [SpinnerColor.Metadata]: TextColor.Metadata,
  [SpinnerColor.LightBlue]: TextColor.Accent,
  [SpinnerColor.Dark]: TextColor.StaticWhite,
};

const labelSizes: Record<SpinnerSize, NumericFontSize> = {
  [SpinnerSize.Large]: 300,
  [SpinnerSize.Medium]: 300,
  [SpinnerSize.Small]: 300,
  [SpinnerSize.XSmall]: 200,
};

const fluentSpinnerSizes = {
  [SpinnerSize.Large]: FluentSpinnerSize.large,
  [SpinnerSize.Medium]: FluentSpinnerSize.medium,
  [SpinnerSize.Small]: FluentSpinnerSize.small,
  [SpinnerSize.XSmall]: FluentSpinnerSize.xSmall,
};

const Spinner: FC<SpinnerProps> = ({
  className,
  text,
  hideText,
  textPosition = TextPosition.Beside,
  color = SpinnerColor.Light,
  size = SpinnerSize.Medium,
  isCentered = false,
}) => {
  const theme = useTheme();
  const textSize = labelSizes[size];
  const classNames = getClassNames(size, isCentered, textSize, textPosition, className);

  const label = hideText ? (
    <ScreenreaderText>{text}</ScreenreaderText>
  ) : (
    <Text className={classNames.label} color={labelColors[color]} size={textSize}>
      {text}
    </Text>
  );

  return (
    <div className={classNames.spinner}>
      <FluentSpinner size={fluentSpinnerSizes[size]} styles={getFluentSpinnerStyles(color, theme)} />
      {label}
    </div>
  );
};

/**
 * A `Spinner` is an outline of a circle which animates around itself indicating to the user that
 * things are processing. It is shown when we're unsure how long a task will take.
 */
export default Spinner;
