import { cancelFirstPageLoadMeasure, reportFirstPageLoad } from '@ms/yammer-data/dist/telemetry';
import { requestAnimationFrameAfterStyleAndLayout } from '@ms/yammer-hooks/dist/performance';
import { UiComponentName } from '@ms/yammer-telemetry-support';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { getPageLoadId, incrementPageLoadId } from '../../../telemetry/pageLoadId';
import {
  cancelPageLoadTimingEvent,
  clearCachedAppPageLoadTracking,
  endAndReportCachedAppPageLoadTimingEventAfterStyleAndLayout,
  endAndReportPageLoadTimingEventAfterStyleAndLayout,
  isCachedAppPageLoad,
  startPageLoadTiming,
  updatePageLoadTimingEvent,
} from '../../../telemetry/pageLoadTiming';
import { PageTelemetryContextValue } from '../context';

import { useDidVisibilityStateChange } from './useDidVisibilityStateChange';

export type UsePageLoadTelemetryCallbackOptions = Pick<
  PageTelemetryContextValue,
  'page' | 'path' | 'hero' | 'isRoutelessApp'
>;
type UsePageLoadTelemetryCallback = (
  options: UsePageLoadTelemetryCallbackOptions
) => (component: UiComponentName) => void;

/**
 * Reports FirstPageLoad, route-to-route PageLoad, and CachedFirstPageLoad times, handling abandon/cancel and timeout events.
 * Returns a callback to be called by any hero component, which manages reporting after the appropriate browser paint delay.
 *
 * NOTE: This hook expects to be unmounted when the page/route changes (which is handled by the owning <AppPageRoutesWithTelemetry> component).
 */
export const usePageLoadTelemetryCallback: UsePageLoadTelemetryCallback = ({ page, path, hero, isRoutelessApp }) => {
  const pageLoadIdRef = useRef(getPageLoadId());
  const { pathname } = useLocation();

  useMemo(
    () => {
      pageLoadIdRef.current = incrementPageLoadId();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  useMemo(
    function startNewPageLoadTimingEventImmediatelyAfterRouteChange() {
      startPageLoadTiming({
        pageLoadId: pageLoadIdRef.current,
        eventProperties: { page, path, hero, didVisibilityStateChange: false },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageLoadIdRef.current]
  );

  const { didVisibilityStateChange, didVisibilityStateChangeRef } = useDidVisibilityStateChange({
    key: `${page}-${pageLoadIdRef.current}`,
  });

  useEffect(
    function updatePendingPageLoadEventOnVisibilityChanges() {
      if (didVisibilityStateChange) {
        updatePageLoadTimingEvent({
          pageLoadId: pageLoadIdRef.current,
          eventProperties: { page, path, hero, didVisibilityStateChange },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [didVisibilityStateChange]
  );

  useEffect(
    () =>
      function cancelPendingPageLoadEventsOnRouteChange() {
        cancelFirstPageLoadMeasure({
          eventProperties: {
            page,
            path,
            hero,
            didVisibilityStateChange: didVisibilityStateChangeRef.current,
          },
        });

        if (!isCachedAppPageLoad({ isRoutelessApp })) {
          cancelPageLoadTimingEvent({
            pageLoadId: pageLoadIdRef.current,
            eventProperties: {
              page,
              path,
              hero,
              didVisibilityStateChange: didVisibilityStateChangeRef.current,
            },
          });
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  return useCallback(
    function reportHeroRendered(component) {
      if (component === hero) {
        const eventProperties = {
          page,
          path,
          hero,
          didVisibilityStateChange: didVisibilityStateChangeRef.current,
        };

        endAndReportPageLoadTimingEventAfterStyleAndLayout({ pageLoadId: pageLoadIdRef.current, eventProperties });

        if (isCachedAppPageLoad({ isRoutelessApp })) {
          endAndReportCachedAppPageLoadTimingEventAfterStyleAndLayout({
            eventProperties: {
              ...eventProperties,
              deepLink: true,
            },
          });
          clearCachedAppPageLoadTracking({ isRoutelessApp });
        }

        requestAnimationFrameAfterStyleAndLayout(() => {
          reportFirstPageLoad({ eventProperties });
        });
      }
    },
    [hero, page, path, didVisibilityStateChangeRef, isRoutelessApp]
  );
};
