import { create } from 'zustand';

import { GlobalCopilotInstance, useGlobalCopilotInstanceStore } from './globalCopilotInstanceStore';

export interface GlobalCopilotState {
  /*
   * Whether the Global Copilot is open.
   */
  readonly isOpen: boolean;
  /*
   * Opens the Global Copilot. Does nothing if the Global Copilot is already open.
   * @returns A promise that resolves to the current Global Copilot instance once it is loaded and ready.
   */
  open(): Promise<GlobalCopilotInstance>;
  /*
   * Closes the Global Copilot.
   */
  close(): void;
  /*
   * Toggles the Global Copilot open state.
   */
  toggleOpen(): void;
}

export const useGlobalCopilotStore = create<GlobalCopilotState>((set) => {
  const open = () => ({ isOpen: true });
  const close = () => {
    useGlobalCopilotInstanceStore.getState().resetInstancePromise();

    return { isOpen: false };
  };

  return {
    isOpen: false,
    open: () => {
      set(open);

      return useGlobalCopilotInstanceStore.getState().instancePromise;
    },
    close: () => set(close),
    toggleOpen: () => set((state) => (state.isOpen ? close() : open())),
  };
});
