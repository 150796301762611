import { Text as FluentText } from '@fluentui/react-components';
import React, { CSSProperties, FC } from 'react';

import { useTheme } from '../../util/theme';
import ScreenReaderText from '../ScreenreaderText';

import { getInlineStyles, useClassNames } from './Text.styles';
import { TextProps } from './Text.types';

const Text: FC<TextProps> = ({
  as,
  font,
  italic,
  size,
  strikethrough,
  underline,
  weight,

  backgroundColor,
  backgroundAlpha = 100,
  color,
  dir,
  display = 'inline',
  language,
  preset,
  screenreaderText,
  uppercase,
  className,
  children,
}) => {
  const theme = useTheme();
  const classNames = useClassNames({
    backgroundColor,
    color,
    display,
    font,
    preset,
    size,
    uppercase,
    weight,
    className,
  });

  const inlineStyles = getInlineStyles({
    size,
    preset,
    color,
    backgroundColor,
    backgroundAlpha,
    theme,
  });

  const content =
    screenreaderText == null ? (
      children
    ) : (
      <>
        <span aria-hidden={true}>{children}</span>
        <ScreenReaderText>{screenreaderText}</ScreenReaderText>
      </>
    );

  return (
    <FluentText
      as={as}
      dir={dir}
      font={font}
      italic={italic}
      lang={language}
      size={size}
      strikethrough={strikethrough}
      underline={underline}
      style={inlineStyles as CSSProperties}
      weight={weight}
      className={classNames.text}
    >
      {content}
    </FluentText>
  );
};

/**
 * A `Text` component simply renders a `span`. It offers size and color props so UI features don't
 * need to own this CSS. This is both a convenience for engineers and a way to enforce consistency
 * of supported text colors and `font-size`/`line-height` combinations.
 */
export default Text;
