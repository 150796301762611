import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';
import React, { FC, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import PageTelemetryContextProvider from '../PageTelemetryContextProvider';

import { AppPageRoutesWithTelemetryProps } from './AppPageRoutesWithTelemetry.types';

const AppPageRoutesWithTelemetry: FC<AppPageRoutesWithTelemetryProps> = ({ routes, PageLoadingSpinner }) => {
  const pageRoutes = useMemo(
    () =>
      routes.map(({ page, path, exact, loader, hero }) => {
        const LoadablePageContent = lazyLoadOnRender({ loader, loading: PageLoadingSpinner });

        return (
          <Route
            key={page}
            path={path}
            exact={exact}
            render={() => (
              <PageTelemetryContextProvider isRoutelessApp={false} page={page} hero={hero} path={path}>
                <LoadablePageContent />
              </PageTelemetryContextProvider>
            )}
          />
        );
      }),
    [PageLoadingSpinner, routes]
  );

  return <Switch>{pageRoutes}</Switch>;
};

export default AppPageRoutesWithTelemetry;
