import { CopilotChatRef, MenuProps, ZQMV2Props } from '@1js/officeaicopilot-chat-types';
import { RefObject } from 'react';
import { create } from 'zustand';

export interface GlobalCopilotInstance {
  readonly copilotChatRef: RefObject<CopilotChatRef>;
  setZeroPromptGuide(zeroPromptGuide: ZQMV2Props | undefined): void;
  setMenuProps(menuProps: MenuProps | undefined): void;
}

interface GlobalCopilotInstanceStore {
  readonly instancePromise: Promise<GlobalCopilotInstance>;
  updateInstance(instance: GlobalCopilotInstance): void;
  resetInstancePromise(): void;
}

export const useGlobalCopilotInstanceStore = create<GlobalCopilotInstanceStore>((set) => {
  let updateInstance: (instance: GlobalCopilotInstance) => void;

  const createInstancePromise = () =>
    new Promise<GlobalCopilotInstance>((resolve) => {
      updateInstance = resolve;
    });

  return {
    resetInstancePromise: () => set({ instancePromise: createInstancePromise() }),
    instancePromise: createInstancePromise(),
    updateInstance: (instance) => updateInstance(instance),
  };
});
